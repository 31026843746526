import React, { useContext } from 'react';
import { PageProps, graphql } from 'gatsby';
import NewsDetail from '@/components/News/NewsDetail';
import { Context } from '@/components/Layout';

const NewsPage: React.FC<PageProps<GatsbyTypes.NewsPageQuery>> = ({ data }) => {
  const loading = useContext(Context);
  return !loading && <NewsDetail data={data} />;
};

export default NewsPage;

export const query = graphql`
  query NewsPage($NewsPageId: String!) {
    microcmsNews(newsId: { eq: $NewsPageId, ne: "template" }) {
      id
      newsId
      title
      body {
        plain
        rich
      }
      publishedAt(formatString: "YYYY/MM/DD hh:mm")
      createdAt(formatString: "YYYY/MM/DD hh:mm")
      category
      contact {
        id
        button
        tayori
      }
    }
  }
`;
